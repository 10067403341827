/* Existing CSS (Desktop / Default) */
.dashboard-heading {
    font-size: 28px;
    font-weight: bold;
    margin-top: 40px;
    margin-bottom: 20px;
    text-align: left;
    margin-left: 140px;
}

.dashboard-card {
    background-color: #f8f9fa;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin: 20px auto;
    width: 80%;
    height: 100px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
}

.dashboard-text {
    font-size: 18px;
    font-weight: bold;
    text-align: left;
    margin-left: 40px;
    margin-top: 10px;
}

.dashboard-address-container {
    display: flex;
    align-items: center;
    margin-left: 20px;
    margin-top: 5px;
}

.dashboard-address {
    font-size: 16px;
    word-break: break-word;
    text-align: left;
    cursor: pointer;
    margin-left: 142px;
    transition: transform 0.3s ease, border 0.3s ease;
    margin-top: -32px;
    color: #0f579a;
}

.dashboard-address1 {
    display: none;
}

.dashboard-address:hover {
    transform: scale(1.1);
    border: 1px solid #ddd;
    border-radius: 2px;
    padding: 2px;
}

.dashboard-balance {
    font-size: 24px;
    word-break: break-word;
    text-align: left;
    margin-left: 160px;
    margin-top: -30px;
}

.copy-button {
    margin-left: 20px;
    background: #a40d0d;
    border: none;
    color: white;
    padding: 5px 10px;
    font-size: 14px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: -30px;
}

.addresscopy-button {
    background: #a40d0d;
    border: none;
    color: white;
    padding: 5px 10px;
    font-size: 14px;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
}

.copy-button:hover {
    background: #8e0b0b;
}

.receive-button {
    background-color: #0f9844;
    color: #ffffff;
    margin-left: 20px;
    border: none;
    padding: 5px 10px;
    font-size: 14px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: -30px;
}

.send-button {
    background: #0f579a;
    color: #ffffff;
    margin-left: 20px;
    border: none;
    padding: 5px 10px;
    font-size: 14px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: -30px;
}

.alert {
    width: 80%;
    margin: 20px auto;
    padding: 15px;
    border-radius: 5px;
    font-size: 16px;
    text-align: center;
}

.alert-info {
    background-color: #e7f3fe;
    border-color: #b3d7ff;
    color: #31708f;
}

.dashboard-card-usdt {
    background-color: #f8f9fa;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-left: 126px;
    width: 39%;
    height: 80px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
}

.dashboard-card-inr {
    background-color: #f8f9fa;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-left: 646px;
    width: 39%;
    height: 80px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
    margin-top: -80px;
}

.value-card {
    margin-bottom: 40px;
}

.dashboard-usdt-text {
    margin-top: 10px;
    text-align: left;
    color: #718096;
    margin-left: 60px;
    font-weight: bold;
}

.dashboard-usdt-balance {
    font-size: 27px;
    margin-top: 0px;
    text-align: left;
    margin-left: 60px;
}

.dashboard-usdt-text1 {
    color: #718096;
    font-size: 14px;
    font-weight: bold;
    margin-top: -26px;
    text-align: left;
    margin-left: 160px;
}

.rubybalancelogo {
    text-align: left;
    width: 2.5%;
    height: 2.5%;
    margin-right: 4px;
    margin-top: -5px;
}

.usdtlogo {
    text-align: left;
    width: 6.5%;
    height: 6.5%;
    margin-right: 4px;
    margin-top: -5px;
}

.inrlogo {
    text-align: left;
    width: 5%;
    height: 5%;
    margin-right: 4px;
    margin-top: -5px;
}

/* ------------------------------------
     RESPONSIVE / MOBILE VIEW
     ------------------------------------ */



.dashbord-tabs {
    text-align: left;
    margin-left: 100px;
    margin-top: 10px;
    margin-bottom: 20px;
}

.dashbord-button {
    border: 20px;
    padding: 10px 20px;
    cursor: pointer;
    margin: 0 5px;
    background-color: transparent;
    border: 5px;
    outline: none;
    font-weight: 600;
    font-size: 20px;
}

.dashbord-button.active {
    background-color: #ebf8ff;
    color: black;
    border: 5px;
    border-radius: 8px;
}

.dashbord-button:hover {
    color: #007bff;
}


.recent-transactionsheading {
    background-color: #f8f9fa;
    border: 1px solid #ddd;
    margin-left: 120px;
    width: 82%;
    height: 40px;
    position: relative;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: -10px;
    font-size: 14px;
}

.qr-code-container {
    background-color: #f8f9fa;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin: 0px auto;
    width: 50%;
    height: 250px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
    position: relative;
    z-index: 2;
}



.Alltransfer-Type-text {
    display: none;
}



.dashboard-Transactions {
    margin-left: 50px;
    margin-right: 50px;
}

.dashbordtransction-Method {
    display: none;
}

.dashbordtransction-hash {
    color: #2C5282;
    margin-right: 30px;
    margin-top: -20px;
}

.transfer-type {
    color: #2C5282;
    background-color: #bdd1e9;
    border-radius: 6px;
    padding-bottom: 1px;
    padding-left: 5px;
    padding-bottom: 1px;
    padding-right: 5px;
    margin-top: 20px;
    display: inline-block;
}

.dashboard-contractaddress {
    text-align: right;
    display: flex;
    margin-right: 100px;
    color: #235f9b;
}

.dashboard-contractbalance {
    margin-right: 700px;
    display: flex;
    color: #235f9b;
    text-align: right;

}

.dashboard-token-name {
    display: flex;
    color: #235f9b;
    margin-left: 100px;
}

.dashbord-token-detail {
    width: 100%;
    margin: 20px auto;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 15px;
    overflow-x: auto;



}

.dashboard-token-transfer {
    display: flex;
    color: #235f9b;
    margin-left: 250px;
}

.dashboard-send {
  color: #fff;
  cursor: pointer;
  background-color: #972525; /* Red for "Send" */
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.3s ease-in-out;
}

.dashboard-send:hover {
  background-color: #e04b4b; /* Darker red on hover */
}

.dashboard-receive {
  color: #fff;
  cursor: pointer;
  background-color: #228313; /* Green for "Receive" */
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.3s ease-in-out;
}

.dashboard-receive:hover {
  background-color: #1b6e10; /* Darker green on hover */
}

.dashboard-send, .dashboard-receive {
  margin: 0 5px; /* Space between the buttons */
  display: inline-block;
}




.contract-copy {
    color: #bad4db;
    margin-right: 20px;
    cursor: pointer;
    background-color: #349598;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 5px;

}

@media (max-width: 768px) {
    /* Heading */
    .dashboard-heading {
      font-size: 20px;
      text-align: center;
      margin-left: 0;
      margin-top: 20px;
      margin-bottom: 10px;
    }
  
    /* Dashboard Card */
    .dashboard-card {
      width: 90%;
      height: auto;
      margin: 10px auto;
      padding: 10px;
    }
  
    /* Text Elements */
    .dashboard-text {
      margin-top: 10px;
      font-size: 20px; /* Increased from 16px */
      text-align: center;
    }
    .dashboard-balance {
        display: flex;
        margin: 10px auto;
        margin-top: -30px;
        font-size: 20px;
      }
    
    /* Address Container & Display */
    .dashboard-address-container {
      flex-direction: column;
      align-items: flex-start;
      margin-left: 10px;
      margin-top: 10px;
    }
  
    .dashboard-address {
      display: none;
      margin: 0;
      margin-top: 5px;
      text-align: center;
    }
  
    .dashboard-address1 {
      color: #0f579a;
      display: flex;
      margin-left: 0;
      margin-top: 10px;
    }
  
    /* Buttons */
    .copy-button,
    .receive-button,
    .send-button {
      margin-top: 10px;
      margin-left: 0;
      width: 80%;
      text-align: center;
    }
  
    .addresscopy-button {
      display: flex;
      text-align: center;
      margin-left: 1rem;
    }
  
    /* Balance Display */

    /* USDT and INR Cards */
    .value-card {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    .dashboard-card-usdt,
    .dashboard-card-inr {
      width: 80%;
      margin: 10px auto;
      height: auto;
    }
  

  
    /* USDT/INR Text and Balances */
    .dashboard-usdt-text,
    .dashboard-usdt-balance,
    .dashboard-usdt-text1 {
      margin-left: 20px;
    }
  
    .dashboard-usdt-text {
      margin-top: 10px;
      font-size: 16px;
    }
  
    .dashboard-usdt-balance {
      font-size: 20px;
    }
  
    /* Additional Components */
    .dashbord-tabs {
      margin-left: 20px;
    }
  
    .dashbord-button {
      font-size: 14px;
    }
  
    .rubybalancelogo {
      text-align: left;
      width: 10%;
      height: 7%;
      margin-right: 4px;
      margin-top: 5px;
    }
  
    .recent-transactionsheading {
      display: none;
    }
  
    .dashbordtransction-Method {
      display: flex;
      text-align: right;
      margin-top: 34px;
      margin-left: 300px;
      cursor: pointer;
      position: relative;
    }
  
    .dashbordtransctionTansfercss {
      background-color: #efefef;
      color: #0f9844;
      border-radius: 17px;
      display: inline-block;
      margin-top: -50px;
      padding: 12px 10px 0px 10px;
      font-size: 14px;
      position: relative;
    }
  
    .qr-code-container {
      background-color: #f8f9fa;
      border: 1px solid #ddd;
      border-radius: 8px;
      margin-left: 40px;
      width: 80%;
      height: 300px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      position: relative;
      z-index: 2;
    }
  
    .usersendrecived-transfer {
      text-align: right;
      margin-top: -20px;
      border-radius: 5px;
      padding: 0 5px;
      position: relative;
    }
  
    .usersendrecived-transfer1 {
      color: green;
      background-color: rgb(194, 225, 194);
      border-radius: 5px;
      padding: 0 5px;
      display: inline-block;
      position: relative;
    }
  
    .dashbord-token-detail {
      display: none;
    }
  
    .dashboard-send {
      color: #9b2323;
      margin-right: 20px;
      cursor: pointer;
      background-color: #d29686;
      padding: 0 5px;
      border-radius: 5px;
    }
  
    .dashboard-token-transfer {
      margin-top: -30px;
    }
  
    .alert {
      width: 90%;
    }
  }
  