

/* Card Container for Blocks */
.block-cards {
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: transform 0.3s ease-in-out;
}

.block-cards:hover {
  transform: translateY(-5px);
}

/* Block Table Styling */
.block-cards table {
  width: 100%;
  border-collapse: collapse;
}

.block-cards tr {
  border-bottom: 1px solid #f0f0f0;
}

.block-cards tr:hover {
  background-color: #fafafa;
}

.block-cards td {
  padding: 10px;
  vertical-align: middle;
}

/* Block Image */
.block-logo {
  width: 30px;
  margin-right: 10px;
  vertical-align: middle;
}

/* Block Number */
.block-blockNumber {
  font-size: 16px;
  font-weight: 600;
  color: #333;
}

/* Timestamp */
.block-timestamp {
  font-size: 12px;
  color: #999;
}

/* Transaction Details */
.block-Trx {
  font-size: 14px;
  color: #555;
}

/* Validator and Hash */
.blockHash-Validator{
  font-size: 12px;
  color: #666;
  word-break: break-all;
  margin-top: 4px;
}

/* Heading Style */
.headingBlocks {
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: 700;
  color: #222;
}

/* "View all blocks" Button */
.buttenallblock {
  margin-top: 15px;
  padding: 10px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.buttenallblock:hover {
  background-color: #0056b3;
}
