/* Desktop / default styles remain the same */
.latesttransaction-table-container {
    padding: 10px;
}

.latesttransaction-table-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 15px;
}

.latesttransaction-table-body {
    background: #f8f9fa;
    border-radius: 10px;
    padding: 10px;
}

.latesttransaction-category {
    font-weight: bold;
    color: #2C5282;
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.latesttransaction-status.success {
    font-weight: bold;
    color: #24702a;
    display: flex;
    align-items: center;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.latesttransaction-status.SendReceived {
    font-weight: bold;
    color: #741d1d;
    display: flex;
    align-items: center;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.latesttransaction-hash img {
    width: 15px;
}

.latesttransaction-copy-icon {
    cursor: pointer;
    margin-left: 5px;
}

.latesttransaction-time {
    font-size: 14px;
    color: #666;
}

.latesttransaction-hash,
.latesttransaction-sender,
.latesttransaction-receiver {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
    color: #2C5282;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.latesttransaction-sender img,
.latesttransaction-receiver img {
    width: 18px;
    height: 18px;
}

.latesttransaction-value,
.latesttransaction-fee {
    font-size: 14px;
    font-weight: bold;
}

.latesttransaction-view-all {
    text-align: center;
    cursor: pointer;
    color: #2C5282;
    margin-top: 10px;
}

.latesttransaction-address-icon,
.latesttransaction-address-icon-alt {
    width: 18px;
    height: 18px;
    margin-right: 5px;
}

/* ---------------------------
   MOBILE (<= 768px) 2x2 Layout
   --------------------------- */
   @media (max-width: 768px) {
    .latesttransaction-table-body table tbody tr {
      display: flex;
      flex-wrap: wrap;
      background-color: #fff;
      border-radius: 12px;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
      padding: 20px;
      margin-bottom: 15px; /* space between cards */
      transition: transform 0.3s ease-in-out;
    }
  
    /* Optional: Add a hover effect for a more interactive feel */
    .latesttransaction-table-body table tbody tr:hover {
      transform: translateY(-5px); /* slight lift on hover */
    }
  
    .latesttransaction-table-body table tbody tr td {
      width: 50%;
      box-sizing: border-box;
    }
  
    /* Adjust font sizes or icon sizes for mobile if needed */
    .latesttransaction-hash,
    .latesttransaction-category,
    .latesttransaction-sender,
    .latesttransaction-receiver,
    .latesttransaction-time,
    .latesttransaction-value,
    .latesttransaction-fee {
      font-size: 14px;
    }
  }
  
