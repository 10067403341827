/* searchComponent.css */
.search-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px auto;
    width: 90%;
  }
  
  .search-container input[type="text"] {
    width: 100%;
    padding: 10px;
    border: 2px solid #ccc;
    border-radius: 20px;
    font-size: 16px;
    outline: none;
    transition: border-color 0.3s;
  }
  
  .search-container input[type="text"]:focus {
    border-color: #007bff;
  }
  
  .search-container button {
    margin-top: 8px;
    padding: 8px 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .search-container button:hover {
    background-color: #0056b3;
  }
  
  .history-dropdown {
    position: absolute;
    top: 50px;
    width: 100%;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-height: 200px;
    overflow-y: auto;
    z-index: 1;
  }
  
  .history-header {
    display: flex;
    justify-content: space-between;
    padding: 8px;
    background-color: linear-gradient(90deg, rgba(122,152,230,1) 0%, rgba(19,160,203,1) 100%);
    border-bottom: 1px solid #ccc;
    
  }
  
  .history-header span {
    font-weight: bold;
  }
  
  .history-header button {
    background: transparent;
    border: none;
    color: #007bff;
    cursor: pointer;
  }
  
  .history-dropdown ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  
  .history-dropdown li {
    padding: 8px 12px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .history-dropdown li:hover {
    background-color: #f0f0f0;
  }
  
  .remove-item {
    margin-left: 10px;
    color: red;
    cursor: pointer;
  }
  

  .filtered-results {
    margin-top: 10px;
    max-height: 90%;
    width: 120%;
    overflow-y: auto;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    background-color: #ebf8ff;
  }
  
  .results-table {
    width: 100%;
    border-collapse: collapse;
    font-family: Arial, sans-serif;
  }
  
  .results-table th,
  .results-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .results-table th {
    background-color: #f4f4f4;
    font-weight: bold;
  }
  
  .results-table tr:hover {
    background-color: #f1f1f1;
    cursor: pointer;
  }
  
  .results-table td {
    color: #333;
  }
  
  .results-table td:hover {
    color: #007bff;
  }
  

.query-header{
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  
}

  